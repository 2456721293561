import Layout from "./Layout";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { AppDispatch } from "@/store";
import { CButton } from "@coreui/react-pro";
import { useEffect, useState } from "react";
import axios from "../../../shared/config/axios-interceptor";
import { ToastError, ToastSuccess } from "@/components/shared/toast/Toast";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { formatVND } from "@/shared/utils/ultils";

const Recharge = () => {
  const { location, navigate } = useRouter();

  const { user } = useSelector((state: RootState) => state.authentication);
  const dispatch = useDispatch<AppDispatch>();

  const [money, setMoney] = useState(0);
  const [disable, setDisable] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (Number.isNaN(Number(value))) return;
    setMoney(Number(value));
  };

  const onSubmit = async () => {
    if (money < 30) {
      ToastError("Số điểm nạp phải từ 30");
      return;
    }

    try {
      setDisable(true);
      const response = await axios.post("/transaction", {
        money,
        type: 1,
      });
      if (response) {
        setMoney(0);
        setDisable(false);
        ToastSuccess("Gửi nạp tiền thành công!!!");
      }
    } catch (error) {
      setDisable(false);
    }
  };

  const bankOptions = [
    { value: "ICB", name: "VietinBank" },
    { value: "VCB", name: "Vietcombank" },
    { value: "BIDV", name: "BIDV" },
    { value: "VBA", name: "Agribank" },
    { value: "OCB", name: "OCB" },
    { value: "MB", name: "MBBank" },
    { value: "TCB", name: "Techcombank" },
    { value: "ACB", name: "ACB" },
    { value: "VPB", name: "VPBank" },
    { value: "TPB", name: "TPBank" },
    { value: "STB", name: "Sacombank" },
    { value: "HDB", name: "HDBank" },
    { value: "VCCB", name: "VietCapitalBank" },
    { value: "SCB", name: "SCB" },
    { value: "VIB", name: "VIB" },
    { value: "SHB", name: "SHB" },
    { value: "EIB", name: "Eximbank" },
    { value: "MSB", name: "MSB" },
    { value: "CAKE", name: "CAKE" },
    { value: "Ubank", name: "Ubank" },
    { value: "TIMO", name: "Timo" },
    { value: "VTLMONEY", name: "ViettelMoney" },
    { value: "VNPTMONEY", name: "VNPTMoney" },
    { value: "SGICB", name: "SaigonBank" },
    { value: "BAB", name: "BacABank" },
    { value: "PVCB", name: "PVcomBank" },
    { value: "Oceanbank", name: "Oceanbank" },
    { value: "NCB", name: "NCB" },
    { value: "SHBVN", name: "ShinhanBank" },
    { value: "ABB", name: "ABBANK" },
    { value: "VAB", name: "VietABank" },
    { value: "NAB", name: "NamABank" },
    { value: "PGB", name: "PGBank" },
    { value: "VIETBANK", name: "VietBank" },
    { value: "BVB", name: "BaoVietBank" },
    { value: "SEAB", name: "SeABank" },
    { value: "COOPBANK", name: "COOPBANK" },
    { value: "LPB", name: "LPBank" },
    { value: "KLB", name: "KienLongBank" },
    { value: "KBank", name: "KBank" },
    { value: "KBHN", name: "KookminHN" },
    { value: "KEBHANAHCM", name: "KEBHanaHCM" },
    { value: "KEBHANAHN", name: "KEBHANAHN" },
    { value: "MAFC", name: "MAFC" },
    { value: "CITIBANK", name: "Citibank" },
    { value: "KBHCM", name: "KookminHCM" },
    { value: "VBSP", name: "VBSP" },
    { value: "WVN", name: "Woori" },
    { value: "VRB", name: "VRB" },
    { value: "UOB", name: "UnitedOverseas" },
    { value: "SCVN", name: "StandardChartered" },
    { value: "PBVN", name: "PublicBank" },
    { value: "NHB HN", name: "Nonghyup" },
    { value: "IVB", name: "IndovinaBank" },
    { value: "IBK - HCM", name: "IBKHCM" },
    { value: "IBK - HN", name: "IBKHN" },
    { value: "HSBC", name: "HSBC" },
    { value: "HLBVN", name: "HongLeong" },
    { value: "GPB", name: "GPBank" },
    { value: "DOB", name: "DongABank" },
    { value: "DBS", name: "DBSBank" },
    { value: "CIMB", name: "CIMB" },
    { value: "CBB", name: "CBBank" }
];

  const [config, setConfig] = useState<any>(null);
  const getConfig = async () => {
    try {
      const response = await axios.get(
        '/config/1',
      )
      setConfig(response?.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getConfig()
  }, [])

  return (
    <Layout>
      <div className="box-withdraw box-withdraw-wap">
        <div className="box-header">
          <ArrowLeft
            style={{ position: "absolute", left: 16 }}
            onClick={() => navigate(-1)}
          />

          <h5 className="m-0 text-white">Nạp điểm</h5>
        </div>

        <div className="text-center my-5">
          <div style={{ fontSize: 30, color: "#00be6e" }}>
            {formatVND(user?.money ?? 0)}
          </div>
          <div style={{ fontSize: 14, color: "#333" }}>Số dư hiện tại</div>
        </div>

        <div className="box-content mt-4">
          <div className="d-flex align-items-center form-withdraw gap-4">
            <label>Số điểm nạp</label>
            <input
              className="amount"
              type="text"
              onChange={(e) => onChange(e)}
              placeholder="Nhập số tiền rút"
              value={money}
            />
          </div>

          <div className="mt-4">
            <CButton
              disabled={disable}
              className="w-100 submit"
              onClick={onSubmit}
            >
              Xác nhận
            </CButton>
          </div>

          <div className="p-3 mt-4" style={{ background: "gray", borderRadius: "10px"}}>
              <div className="text-white">Tên ngân hàng: {bankOptions.find( (i: any) => i.value === config?.bank)?.name}</div>
              <div className="text-white mt-2">Số tài khoản: {config?.bankCode}</div>
              <div className="text-white mt-2">Chủ tài khoản: {config?.cardName}</div>
           </div>

          <img className={"w-100 mt-3"} src={`https://img.vietqr.io/image/${config?.bank}-${config?.bankCode}-compact.jpg`}/>

        </div>
      </div>
    </Layout>
  );
};

export default Recharge;
