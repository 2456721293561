import avatar from '@/assets/img/avatar.jpg'
import SendIcon from '@/components/shared/icons/SendIcon'
import { RootState } from '@/reducers'
import { socket } from '@/shared/config/socket'
import { IMessage } from '@/shared/model/message.model'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { AppDispatch } from '@/store'
import {
  CAvatar,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CSpinner,
} from '@coreui/react-pro'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEntities } from '../Chat/Message/message.api'
import {
  fetching,
  initialMessageFilter,
  messageSelectors,
  resetFilterState,
  setFilterState,
  toggleMessageInfo,
} from '../Chat/Message/message.reducer'
import Layout from './Layout'
import ArrowLeft from '@/components/shared/icons/web/ArrowLeft'
import { ICreateChatResponse } from '@/shared/model/chat.model'
import axios from '../../../shared/config/axios-interceptor'
import { handleUploadImage } from '@/shared/utils/ultils'
import ImageSendIcon from '@/components/shared/icons/web/ImageSendIcon'
import dayjs from 'dayjs'

const Message = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { bodyWidth } = useSelector((state: RootState) => state.container)
  const { user } = useSelector((state: RootState) => state.authentication)
  const { initialState } = useSelector(
    (state: RootState) => state.messageReducer,
  )
  const { messageInfoShow, totalPages, filterState, loading } = initialState
  const listMessage = useSelector(messageSelectors.selectAll)

  const { navigate } = useRouter()
  const fileInput = useRef<any>(null)

  const listMessageRef = useRef<HTMLDivElement>(null)
  const [messages, setMessages] = useState<IMessage[]>([])
  const [messageInput, setMessageInput] = useState('')
  const [file, setFile] = useState('')
  console.log(file)

  const [roomId, setRoomId] = useState(0)

  const chatBoxWidth = messageInfoShow
    ? `calc(100% - ${bodyWidth * 0.25}px)`
    : '100%'

  const toggle = () => {
    dispatch(toggleMessageInfo(!messageInfoShow))
  }

  const isSender = (senderId: number) => senderId === Number(user?.id)

  const isSenderHasNextMessage = (msg: IMessage, index: number) =>
    index > 0 && messages[index - 1].senderId === msg.senderId

  const isSenderHasPreviousMsg = (msg: IMessage, index: number) =>
    index + 1 < messages.length && messages[index + 1].senderId === msg.senderId

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key == 'Enter' && messageInput.trim()) {
      sendMessage()
    }
  }

  const getRoom = async () => {
    try {
      const response = await axios.get('/room/get-room-user')
      setRoomId(response?.data?.id)
      if (!response?.data?.id) {
        socket.emit('new_room', { users: [user?.id, 1], name: 'Bắt đầu' })

        socket.on(`user_${user?.id}_new_room`, (res: ICreateChatResponse) => {
          const messageData = {
            id: res.room?.id,
            content: 'Xin chào tôi có thể giúp gì cho bạn!',
          }

          setRoomId(res.room?.id)

          socket.emit('send_message', messageData)
          socket.off(`user_${user?.id}_new_room`)
        })
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getRoom()
  }, [])

  const scrollToBottom = () => {
    const timeout = setTimeout(() => {
      // Scroll to the bottom of the messages div
      if (listMessageRef.current) {
        listMessageRef.current.scrollTop = listMessageRef.current.scrollHeight
      }
    }, 700)

    return () => clearTimeout(timeout)
  }

  const sendMessage = () => {
    if (!messageInput.trim()) {
      return
    }
    const messageData = {
      id: roomId,
      content: messageInput,
    }
    socket.emit('send_message', messageData)
  }

  const handleScroll = () => {
    const div = listMessageRef.current

    if (div?.scrollTop === 0) {
      console.log('Đã đến đầu list message')
      if (totalPages > 1)
        dispatch(
          setFilterState({ ...filterState, limit: filterState.limit + 50 }),
        )
    }
  }

  useEffect(() => {
    const div = listMessageRef.current

    if (Number(roomId)) {
      dispatch(
        setFilterState({ ...initialMessageFilter, roomId: Number(roomId) }),
      )
    }
    scrollToBottom()

    return () => {
      dispatch(resetFilterState())
      div?.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId])

  useEffect(() => {
    if (filterState.roomId) {
      dispatch(fetching())
      dispatch(getEntities(filterState))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState)])

  useEffect(() => {
    setMessages([...listMessage].reverse())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(listMessage)])

  useEffect(() => {
    socket.on(`user_${user?.id}_new_message`, (newMsg: IMessage) => {
      if (Number(roomId) === newMsg.roomId) {
        setMessageInput('')
        setMessages([...messages, newMsg])
        scrollToBottom()
      }
    })

    socket.on(`user_${user?.id}_remove_message`, (data: any) => {
      const newMessage = messages.filter(msg => msg._id !== data)
      setMessages(newMessage)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, totalPages])

  useEffect(() => {
    const div = listMessageRef.current
    div?.addEventListener('scroll', handleScroll)

    return () => {
      div?.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), totalPages])

  const uploadFile = async (file: File) => {
    // const file =  {};
    try {
      return await handleUploadImage(file)
    } catch (e) {
      console.error('Error uploading file', e)
    }
  }

  const onChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0]
      const messageData = {
        id: roomId,
        content: '',
        image: await uploadFile(file),
      }
      socket.emit('send_message', messageData)
      if (fileInput.current) {
        fileInput.current.value = ''
      }
    }
  }

  const removeMessage = (messageId: string) => {
    const messageData = {
      id: roomId,
      messageId
    }
    socket.emit('remove_message', messageData)
  }

  return (
    <>
      <div className="box-chat-customer box-chat-customer-wap">
        <Layout>
          <div className="message-box" style={{ width: chatBoxWidth }}>
            <div
              style={{ background: '#113b49', height: 55 }}
              className="chat-info-header justify-content-end text-end"
            >
              <div className="d-flex text-sm text-gray-neutral-700 align-items-center">
                <ArrowLeft
                  style={{ position: 'absolute', left: 16 }}
                  onClick={() => navigate(-1)}
                />

                {/* <CAvatar src={chatRoom.avatar || avatar} className="avatar-40 me-12" /> */}
                <div>
                  <p className="m-0 mb-1 text-medium-sm text-white">
                    Chăm sóc khách hàng
                  </p>
                  <p className="m-0 text-xs text-white">Trực tuyến</p>
                </div>
              </div>
            </div>
            <div className="chat-box-container chat-box-container1" ref={listMessageRef}>
              {loading ? (
                <div className="text-center">
                  <CSpinner className="text-primary" />
                </div>
              ) : null}

              {messages.map((msg, index) =>
                isSender(msg.senderId) ? (
                  <div
                    key={`chat-${index}`}
                    className="d-flex justify-content-end"
                    style={{
                      marginBottom:
                        index + 1 === messages.length
                          ? 0
                          : isSenderHasPreviousMsg(msg, index)
                            ? '4px'
                            : '16px',
                    }}
                  >
                    <div className="chat-box primary" style={{ maxWidth: 300 }}>
                      {msg?.image ? (
                        <img width={'100%'} height={'auto'} src={msg?.image} />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: msg.content.replace(/\n/g, '<br/>'),
                          }}
                        ></div>
                      )}
                      <div className='d-flex justify-content-between gap-3 mt-3' style={{ fontSize: 10 }}>
                        <div style={{ fontSize: 10, color: "hsl(209 95% 90.1%/ 1)" }}>{dayjs(msg.timestamp).format('HH:mm DD/MM/YYYY')}</div>
                        {/* <div className='cursor-pointer' onClick={() => removeMessage(msg._id)}>Xóa</div> */}
                      </div>
                    </div>
                    {/* <CTooltip
                      content={dayjs(msg.timestamp).format('HH:mm DD/MM/YYYY')}
                      placement="left"
                      className="position-fixed"
                    >
                      <p
                        className="chat-box primary"
                        style={{
                          marginBottom: isSenderHasPreviousMsg(msg, index)
                            ? 0
                            : '4px',
                          background: '#113b49',
                        }}
                      >
                        {msg?.image ? (
                          <img
                            width={'100%'}
                            height={'100%'}
                            src={msg?.image}
                          />
                        ) : (
                          msg.content
                        )}
                      </p>
                    </CTooltip> */}
                  </div>
                ) : (
                  <div
                    key={`chat-${index}`}
                    className="d-flex align-items-end"
                    style={{
                      marginBottom:
                        index + 1 === messages.length
                          ? 0
                          : isSenderHasPreviousMsg(msg, index)
                            ? '4px'
                            : '16px',
                    }}
                  >
                    <div className="avatar-32 me-2">
                      {isSenderHasPreviousMsg(msg, index) ? null : (
                        <CAvatar
                          src={msg.sender?.avatar || avatar}
                          className="avatar-32"
                        />
                      )}
                    </div>
                    <div>
                      {/* {isSenderHasNextMessage(msg, index) || chatRoom.number_of_members === 2 ? null : (
                      <p className="text-xs text-gray-modern-500 mb-1">{msg.sender?.fullName || msg.sender?.username}</p>
                    )} */}

                      <div className="chat-box" style={{ maxWidth: 300 }}>
                        {msg?.image ? (
                          <img
                            width={'100%'}
                            height={'auto'}
                            src={msg?.image}
                          />
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: msg.content.replace(/\n/g, '<br/>'),
                            }}
                          ></div>
                        )}
                        <div className='d-flex justify-content-between gap-3 mt-3' style={{ fontSize: 10 }}>
                          <div style={{ fontSize: 10, color: "hsl(206 6% 63%/ 1)" }}>{dayjs(msg.timestamp).format('HH:mm DD/MM/YYYY')}</div>
                          {/* <div className='cursor-pointer' onClick={() => removeMessage(msg._id)}>Xóa</div> */}
                        </div>
                      </div>
                      {/*                   
                      <CTooltip
                        content={dayjs(msg.timestamp).format(
                          'HH:mm DD/MM/YYYY',
                        )}
                        placement="left"
                        className="position-fixed"
                      >
                        
                        <p className="chat-box m-0">
                          {msg?.image ? (
                            <img
                              width={'100%'}
                              height={'100%'}
                              src={msg?.image}
                            />
                          ) : (
                            msg.content
                          )}
                        </p>
                      </CTooltip> */}
                    </div>
                  </div>
                ),
              )}
            </div>
            <div className="chat-input-container d-flex align-items-center gap-2">
              <label htmlFor="file">
                <ImageSendIcon />
              </label>
              <input
                type="file"
                id="file"
                className="d-none"
                onChange={onChangeFile}
                ref={fileInput}
              />
              <CInputGroup className="input-start-group">
                <CFormTextarea
                  placeholder="Viết tin nhắn..."
                  name="content"
                  autoComplete="off"
                  value={messageInput}
                  onChange={(e) => setMessageInput(e.target.value)}
                  className="fs-6"
                  onKeyDown={handleKeyDown}
                />
                {
                  <CInputGroupText
                    onClick={sendMessage}
                    className="cursor-pointer"
                  >
                    <SendIcon />
                  </CInputGroupText>
                }
              </CInputGroup>
            </div>
          </div>
        </Layout>
      </div>
    </>
  )
}

export default Message
