import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { AppDispatch } from '@/store'
import { CButton, CFormInput, CFormLabel, CModal, CModalBody, CModalHeader } from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import axios from '../../../../shared/config/axios-interceptor'
import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast'
import { getProfile } from '../../auth/auth.api'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import ArrowLeft from '@/components/shared/icons/web/ArrowLeft'
import { formatVND } from '@/shared/utils/ultils'

const LenhRutTien = () => {
  const { location, navigate } = useRouter()

  const { user } = useSelector((state: RootState) => state.authentication)
  const dispatch = useDispatch<AppDispatch>()

  const [money, setMoney] = useState<any>('')
  const [noBank, setNoBank] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const [password, setPassword] = useState('')

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (Number.isNaN(Number(value))) return
    setMoney(Number(value))
  }

  useEffect(() => {
    if (!user?.cardName || !user?.cardNumber || !user?.bankId) {
      setNoBank(true)
    }
  }, [user?.cardName, user?.cardNumber, user?.bankId])

  const showModalHanler = () => {
    if(!money) {
      ToastError('Vui lòng nhập số tiền rút')
      return;
    }
    setShowModal(true)
  }

  const onSubmit = async () => {
    if (money < 30) {
      ToastError('Số tiền rút phải từ 30')
      return
    }

    if (Number(money) > Number(user?.money)) {
      ToastError('Số tiền không hợp lệ')
      return
    }

    if ((user?.money ?? 0) - money < 0) {
      ToastError('Số tiền không hợp lệ')
      return
    }

    if (!password) {
      ToastError('Vui lòng nhập mật khẩu rút tiền')
      return
    }

    if (!user?.passsWithdraw) {
      ToastError('Vui lòng thiết lập mật khẩu rút tiền trước')
      return
    }

    if (password !== user?.passsWithdraw) {
      ToastError('Mật khẩu rút tiền không đúng')
      return
    }

    try {
      const response = await axios.post('/transaction', {
        money,
        type: 2,
      })
      if(response){
        setMoney(0)
        ToastSuccess('Gửi rút tiền thành công!!!')
        setShowModal(false)
        dispatch(getProfile())
      }
    } catch (error) {
      console.error('Error:', error)
      dispatch(getProfile())
    }
  }

  if (noBank) {
    return (
      <Navigate to="/add-bank" replace state={{ path: location.pathname }} />
    )
  }

  return (
      <div className="p-4" style={{ background: "rgb(56, 65, 82)" }}>
         <h3 className="text-white">Làm lệnh rút tiền</h3>
         <p style={{ color: "#fff", opacity: 0.7, fontSize: 13 }}>Số tiền quý khách rút sẽ được chuyển khoản vào tài khoản ngân hàng này</p>
         <div className="form-group-custom mt-3">
              <CFormLabel style={{ fontSize: 14 }} className="text-white">Ngân hàng</CFormLabel>
              <CFormInput style={{ fontSize: 14 }} type="text" value={user?.bank?.name} readOnly/>
         </div>

         <div className="form-group-custom mt-3">
              <CFormLabel style={{ fontSize: 14 }} className="text-white">Số tài khoản</CFormLabel>
              <CFormInput style={{ fontSize: 14 }} type="text" value={user?.cardNumber ? user?.cardNumber?.toString().replace(/(\d{2})\d{5}/, '$1*****') : ''} readOnly/>
         </div>

         <div className="form-group-custom mt-3">
              <CFormLabel style={{ fontSize: 14 }} className="text-white">Chủ tài khoản</CFormLabel>
              <CFormInput style={{ fontSize: 14 }} type="text" value={user?.cardName} readOnly/>
         </div>


         <div className="form-group-custom mt-3">
              <CFormLabel style={{ fontSize: 14 }} className="text-white">Số tiền rút</CFormLabel>
              <div className="position-relative">
                <CFormInput  style={{ height: 60, color: "red", textAlign: "center", fontSize: 18, paddingRight: 70 }} type="text" value={money} onChange={(e) => onChange(e)} />
                <div style={{ color: "red", fontSize: 18, position: "absolute", right: 30, top: 0, bottom: 0, alignContent: "center" }}>USD</div>
              </div>
         </div>

         <CButton onClick={showModalHanler} className="mt-4 w-100" style={{ background: "rgb(183, 244, 227)", border: "none", color: "#000", boxShadow: "none"}}>GỬI LỆNH RÚT</CButton>

          <CModal visible={showModal} onClose={() => setShowModal(false)} alignment="center">
         
            <CModalBody>

            <div className="text-center mt-2 my-3">Quý khách vui lòng nhập mật khẩu rút tiền !</div>
            <CFormInput type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>

              <div className="d-flex justify-content-between gap-2">
                <CButton onClick={onSubmit} className="mt-4 w-100" style={{ background: "rgb(183, 244, 227)", border: "none", color: "#000", boxShadow: "none"}}>OK</CButton>
                <CButton onClick={() => setShowModal(false)} className="mt-4 w-100" style={{ background: "rgb(152 152 152)", border: "none", color: "#000", boxShadow: "none"}}>HUỶ</CButton>
              </div>

            </CModalBody>
          </CModal>
      </div>
  )
}

export default LenhRutTien
