import { RootState } from '@/reducers'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { AppDispatch } from '@/store'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createEntity,
  getEntities,
  getEntity,
  updateEntity,
} from './configManagement.api'
import { CButton, CCol, CForm, CFormLabel, CRow, CFormInput, CFormSelect } from '@coreui/react-pro'
import CustomFormikEditor from '@/components/shared/CustomEditor/CustomEditor'
import { Formik } from 'formik'
import { IConfig, INewConfig } from '@/shared/model/config.model'
import { fetching, resetEntity, selectEntityById } from './configManagement.reducer'
import { ToastSuccess } from '@/components/shared/toast/Toast'

const ConfigManagement = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { navigate, params } = useRouter()

  const { initialState } = useSelector(
    (state: RootState) => state.configReducer,
  )
  const {
    filterState,
    totalItems,
    totalPages,
    loading,
    updateEntitySuccess,
    deleteEntitySuccess,
  } = initialState

  useEffect(() => {
    if (updateEntitySuccess) {
      ToastSuccess('Cập nhật thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  useEffect(() => {
    dispatch(getEntities(filterState))
  }, [dispatch, filterState])

  const initialValues: IConfig = {
    id: 0,
    key: '',
    content: '',
    bank: '',
    bankCode: '',
    cardName: '',
  }

  const bankOptions = [
    { value: "ICB", name: "VietinBank" },
    { value: "VCB", name: "Vietcombank" },
    { value: "BIDV", name: "BIDV" },
    { value: "VBA", name: "Agribank" },
    { value: "OCB", name: "OCB" },
    { value: "MB", name: "MBBank" },
    { value: "TCB", name: "Techcombank" },
    { value: "ACB", name: "ACB" },
    { value: "VPB", name: "VPBank" },
    { value: "TPB", name: "TPBank" },
    { value: "STB", name: "Sacombank" },
    { value: "HDB", name: "HDBank" },
    { value: "VCCB", name: "VietCapitalBank" },
    { value: "SCB", name: "SCB" },
    { value: "VIB", name: "VIB" },
    { value: "SHB", name: "SHB" },
    { value: "EIB", name: "Eximbank" },
    { value: "MSB", name: "MSB" },
    { value: "CAKE", name: "CAKE" },
    { value: "Ubank", name: "Ubank" },
    { value: "TIMO", name: "Timo" },
    { value: "VTLMONEY", name: "ViettelMoney" },
    { value: "VNPTMONEY", name: "VNPTMoney" },
    { value: "SGICB", name: "SaigonBank" },
    { value: "BAB", name: "BacABank" },
    { value: "PVCB", name: "PVcomBank" },
    { value: "Oceanbank", name: "Oceanbank" },
    { value: "NCB", name: "NCB" },
    { value: "SHBVN", name: "ShinhanBank" },
    { value: "ABB", name: "ABBANK" },
    { value: "VAB", name: "VietABank" },
    { value: "NAB", name: "NamABank" },
    { value: "PGB", name: "PGBank" },
    { value: "VIETBANK", name: "VietBank" },
    { value: "BVB", name: "BaoVietBank" },
    { value: "SEAB", name: "SeABank" },
    { value: "COOPBANK", name: "COOPBANK" },
    { value: "LPB", name: "LPBank" },
    { value: "KLB", name: "KienLongBank" },
    { value: "KBank", name: "KBank" },
    { value: "KBHN", name: "KookminHN" },
    { value: "KEBHANAHCM", name: "KEBHanaHCM" },
    { value: "KEBHANAHN", name: "KEBHANAHN" },
    { value: "MAFC", name: "MAFC" },
    { value: "CITIBANK", name: "Citibank" },
    { value: "KBHCM", name: "KookminHCM" },
    { value: "VBSP", name: "VBSP" },
    { value: "WVN", name: "Woori" },
    { value: "VRB", name: "VRB" },
    { value: "UOB", name: "UnitedOverseas" },
    { value: "SCVN", name: "StandardChartered" },
    { value: "PBVN", name: "PublicBank" },
    { value: "NHB HN", name: "Nonghyup" },
    { value: "IVB", name: "IndovinaBank" },
    { value: "IBK - HCM", name: "IBKHCM" },
    { value: "IBK - HN", name: "IBKHN" },
    { value: "HSBC", name: "HSBC" },
    { value: "HLBVN", name: "HongLeong" },
    { value: "GPB", name: "GPBank" },
    { value: "DOB", name: "DongABank" },
    { value: "DBS", name: "DBSBank" },
    { value: "CIMB", name: "CIMB" },
    { value: "CBB", name: "CBBank" }
];


  const config = useSelector(selectEntityById('1'))

  if(!config) return <></>
  
  return (
    <>
      <div className="tab-outlet-content">
        <Formik
          initialValues={config || initialValues}
          // validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(value) => {
            console.log(value)
            dispatch(fetching())
            if (config) {
              dispatch(updateEntity(value as IConfig))
            } else {
              value.key = 'nottification-first-login'
              dispatch(createEntity(value))
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => (
            <CForm onSubmit={handleSubmit} className="custom-form">
              <CRow>

              <CCol xs={12}>
                  <CRow>
                    
                <CCol xs="12" md={3} lg={2}>
                  <CFormLabel htmlFor="content" style={{ fontSize: 16 }}>
                    Cấu hình thông báo
                  </CFormLabel>
                </CCol>

                <CCol xs="12" md={9} lg={10}>
                  <CFormInput name="content"  
                    value={values.content}
                    onChange={handleChange} />
                </CCol>
                  </CRow>
              </CCol>


              <CCol xs={12} className="mt-4">
                  <CRow>
                    
                <CCol xs="12" md={3} lg={2}>
                  <CFormLabel htmlFor="content1" style={{ fontSize: 16 }}>
                    Cấu hình trang chủ
                  </CFormLabel>
                </CCol>


                <CCol xs="12" md={9} lg={10}>
                  <CustomFormikEditor
                    simpleMode
                    size="sm"
                    name="content1"
                    value={values.content1}
                    setFieldValue={setFieldValue}
                  />
                </CCol>
                  </CRow>
              </CCol>


              <CCol xs={12} className="mt-4">
                  <CRow>
                    
                <CCol xs="12" md={3} lg={2}>
                  <CFormLabel htmlFor="content2" style={{ fontSize: 16 }}>
                    Cấu hình khuyến mại
                  </CFormLabel>
                </CCol>

                <CCol xs="12" md={9} lg={10}>
                  <CustomFormikEditor
                    simpleMode
                    size="sm"
                    name="content2"
                    value={values.content2}
                    setFieldValue={setFieldValue}
                  />
                </CCol>
                  </CRow>
              </CCol>



              <CCol xs={12} className="mt-4">
                  <CRow>
                    
                <CCol xs="12" md={3} lg={2}>
                  <CFormLabel htmlFor="content3" style={{ fontSize: 16 }}>
                    Cấu hình luật chơi
                  </CFormLabel>
                </CCol>

                <CCol xs="12" md={9} lg={10}>
                  <CustomFormikEditor
                    simpleMode
                    size="sm"
                    name="content3"
                    value={values.content2}
                    setFieldValue={setFieldValue}
                  />
                </CCol>
                  </CRow>
              </CCol>

              
              {/* <CCol xs={12} className="mt-3">
                  <CRow>
                    
                <CCol xs="12" md={3} lg={2}>
                  <CFormLabel htmlFor="bank" style={{ fontSize: 16 }}>
                    Tên ngân hàng
                  </CFormLabel>
                </CCol>

                <CCol xs="12" md={9} lg={10}>
                <CFormSelect
                          className="form-height-44"
                          name="bank"
                          value={values.bank}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={!!errors.bank && touched.bank}
                        >
                          {bankOptions.map((item, index) => (
                            <option key={index} value={item.value}>
                              {item.name}
                            </option>
                          ))}
                        </CFormSelect>
                </CCol>
                  </CRow>
              </CCol>

              <CCol xs={12} className="mt-3">
                  <CRow>
                    
                <CCol xs="12" md={3} lg={2}>
                  <CFormLabel htmlFor="content" style={{ fontSize: 16 }}>
                    Chủ ngân hàng
                  </CFormLabel>
                </CCol>

                <CCol xs="12" md={9} lg={10}>
                  <CFormInput
                    type="text"
                    id="cardName"
                    name="cardName"
                    value={values.cardName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                  >
                  </CFormInput>
                </CCol>
                  </CRow>
              </CCol>


              <CCol xs={12} className="mt-3">
                  <CRow>
                    
                <CCol xs="12" md={3} lg={2}>
                  <CFormLabel htmlFor="content" style={{ fontSize: 16 }}>
                    Số tài khoản
                  </CFormLabel>
                </CCol>

                <CCol xs="12" md={9} lg={10}>
                  <CFormInput
                    type="text"
                    id="bankCode"
                    name="bankCode"
                    value={values.bankCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                  >
                  </CFormInput>
                </CCol>
                  </CRow>
              </CCol> */}


                <CCol xs="12" className='mt-4'>
                  <CButton type="submit" className="btn btn-primary">
                    Lưu cấu hình
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          )}
        </Formik>
      </div>
    </>
  )
}

export default ConfigManagement
