import { IGame } from '@/shared/model/game.model'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useRouter } from "@/shared/utils/hooks/useRouter";
import axios from '../../../shared/config/axios-interceptor'
import { formatUSD } from '@/shared/utils/ultils';

interface IData {
  // setDisableDatCuoc: any
  setLoad: any
  load: boolean
  ky: IGame | null
  setShowModalDatCuoc?: any
  showModalDatCuoc?: any
}
const KyQuay = (props: IData) => {
  const { setLoad, load, ky, setShowModalDatCuoc, showModalDatCuoc } = props
  const [history, setHistory] = useState<any>()

  const [timeInterval, setTimeInterval] = useState<any>({ minutes: "00", seconds: "00" })

  const getLatestHistory = async () => {
    try {
      const a =  await axios.get(`/history/latest?kyId=${ky?.id}`)
      setHistory(a.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getLatestHistory()
  }, [ky?.id, load])

  useEffect(() => {
    const gameEndTime = dayjs(ky?.endTime).add(2, 'seconds')

    const interval = setInterval(() => {
      const now = dayjs()
      const difference = gameEndTime.diff(now)

      if (difference > 0) {
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60),
        )
        const seconds = Math.floor((difference % (1000 * 60)) / 1000)
        if(minutes == 0 && seconds <= 30){
          setTimeInterval(null)
        }else {
          let m = minutes < 10 ? '0' + minutes : minutes
          let s = seconds < 10 ? '0' + seconds : seconds
          setTimeInterval({ minutes: m, seconds: s })
        }
      } else {
        setLoad(!load)
        setTimeInterval(null)
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [ky?.endTime, load])

  console.log(history);

  return (
     <div>
      {
         timeInterval &&  <div>
         <div className="text-center py-2">
           <div style={{color: "#fff"}}>Bắt đầu chọn lượt chơi mới</div>
           <div style={{color: "#fff", marginTop: 8}}>Thời gian còn lại
           <span style={{ color: "rgb(36 99 235)", paddingLeft: 10, paddingRight: 10 }}>{timeInterval?.minutes}:{timeInterval?.seconds}</span>để đặt cược</div>
         </div>
         {
           !history?.length && <div className="button-thamgia py-2" style={{ position: "fixed", bottom: 80, left: 0,   right: 0, margin: "auto" }}>
             <div onClick={() => setShowModalDatCuoc(true)} className='px-3 py-2 rounded-3' style={{color: "#fff", background: "rgb(239 69 68)", width: "fit-content", margin: "auto"}}>Tham gia đặt cược</div>
            </div>
         }
       </div>
      }
      {
        history?.length && <div className="mt-3 text-center" style={{ border: "1px solid rgb(96 165 250)", padding: 10, width: "fit-content", margin: "auto"}}>
        <div style={{ color: "rgb(96 165 250)" }}>Quý khách đã đặt cược</div>
        <div style={{ color: "rgb(96 165 250)" }}>Tổng tiền cược: <strong>{formatUSD(history[0]?.money * history?.length)}</strong></div>
        <div style={{ color: "rgb(96 165 250)" }}>Chọn: { history?.map( (item: any) => item?.biDatCuoc.name)?.join(',') }</div>
        <div style={{ color: "rgb(74 222 128)" }}>Quy khách vui lòng đợi kết quả</div>
      </div>
      }
     </div>
  )
}

export default KyQuay
