import Layout from './Layout'
import { useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { useEffect, useState } from 'react'
import { formatVND } from '@/shared/utils/ultils'
import HeaderWap from './components/HeaderWap'
import ThongTinTaiKhoan from './accounts/ThongTinTaiKhoan'
import DoiMatKhau from './accounts/DoiMatKhau'
import LaiLoHomNay from './accounts/LaiLoHomNay'
import MatKhauRutTien from './accounts/MatKhauRutTien'
import TaiKhoanNganHang from './accounts/TaiKhoanNganHang'
import LenhRutTien from './accounts/LenhRutTien'
import LichSuNapTien from './accounts/LichSuNapTien'
import LichSuRutTien from './accounts/LichSuRutTien'
import { useRouter } from '@/shared/utils/hooks/useRouter'

const Account = () => {
  const { location } = useRouter()
  const queryParams = new URLSearchParams(location.search);
  const tabQuery = queryParams.get('tab'); // Replace 'myParam' with your query key

  const { user } = useSelector((state: RootState) => state.authentication)

  const [tab, setTab] = useState(8)

  useEffect(() => {
    if (tabQuery) {
      setTab(parseInt(tabQuery));
    }
  }, [tabQuery]);

  const tabs = [
    {
      name: "Thông tin tài khoản", 
      tab: 1,
      icon: <i className="fa-solid fa-user"></i>,
    },
    {
      name: "Đổi mật khẩu", 
      tab: 2,
      icon: <i className="fa-solid fa-lock"></i>,
    },
    {
      name: "Tài khoản ngân hàng", 
      tab: 3,
      icon: <i className="fa-solid fa-address-card"></i>,
    },
    {
      name: "Mật khẩu rút tiền", 
      tab: 4,
      icon: <i className="fa-solid fa-user"></i>,
    },
    {
      name: "Lệnh rút tiền", 
      tab: 5,
      icon: <i className="fa-solid fa-dollar-sign"></i>,
    },
    {
      name: "Lịch sử rút tiền", 
      tab: 6,
      icon: <i className="fa-solid fa-file"></i>,
    },
    {
      name: "Lịch sử nạp tiền", 
      tab: 7,
      icon: <i className="fa-solid fa-file"></i>,
    },
    {
      name: "Lãi lỗ hôm nay", 
      tab: 8,
      icon: <i className="fa-solid fa-th-large"></i>,
    }
  ]

  return (
    <Layout>
      <div className="box-account-wap">
        <HeaderWap title={"Số dư: " + formatVND(user?.money ?? 0)} />
        <div className="main-content-account p-3">
          <div className="tab-header" style={{ background: 'rgb(56, 65, 82)'}}>
            {
              tabs.map( (item, index) => {
                return (
                  <div key={index}
                  className={
                    tab == item.tab ? 'tab-header-item active py-2 px-3' : 'tab-header-item py-2 px-3'
                  }
                  onClick={() => setTab(item.tab)}
                >
                  <div className="d-flex gap-3 align-items-center" style={{ cursor: 'pointer', color: 'white' }}>
                    {item.icon}
                    <div style={{ fontSize: '14px', fontWeight: '500' }}>{item.name}</div>
                  </div>
                </div>
                )
              })
            }
          </div>

          <div className="tab-content mt-4">
            {
              tab == 1 && <ThongTinTaiKhoan />
            }
            {
              tab == 2 && <DoiMatKhau />
            }    

            {
              tab == 3 && <TaiKhoanNganHang />
            }   

            {
              tab == 4 && <MatKhauRutTien />
            }   

            {
              tab == 5 && <LenhRutTien />
            }       

            {
              tab == 6 && <LichSuRutTien />
            } 

            {
              tab == 7 && <LichSuNapTien />
            }   

            {
              tab == 8 && <LaiLoHomNay />
            }  
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Account
