import { ToastError, ToastSuccess } from "@/components/shared/toast/Toast"
import { RootState } from "@/reducers"
import { AppDispatch } from "@/store"
import { CButton, CFormInput, CFormLabel } from "@coreui/react-pro"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from '../../../../shared/config/axios-interceptor'
import { getProfile } from "../../auth/auth.api"

const MatKhauRutTien =() => {
    const dispatch = useDispatch<AppDispatch>()
  
    const { user } = useSelector((state: RootState) => state.authentication)
  
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
  
    const onChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      if (index === 1) {
        setCurrentPassword(value)
      }
      if (index === 2) {
        setNewPassword(value)
      }
      if (index === 3) {
        setConfirmNewPassword(value)
      }
    }
  
    const onSubmit = async () => {
      if (user?.passsWithdraw) {
        if (!currentPassword || !newPassword || !confirmNewPassword) {
          ToastError('Vui lòng nhập đầy đủ thông tin')
          return
        }
  
        if (currentPassword !== user?.passsWithdraw) {
          ToastError('Mật khẩu cũ sai')
          return
        }
      }
  
      if (!newPassword || !confirmNewPassword) {
        ToastError('Vui lòng nhập đầy đủ thông tin')
        return
      }
  
      if (newPassword !== confirmNewPassword) {
        ToastError('Xác nhận mật khẩu không khớp')
        return
      }
  
      try {
        const response = await axios.put('/user/' + user?.id, {
          passsWithdraw: newPassword,
        })
        if(response){
            ToastSuccess('Cập nhật mật khẩu rút tiền thành công')
            dispatch(getProfile())
        }
      } catch (error) {
        console.error('Error:', error)
      }
    }
  
    return (
        <div className="p-4" style={{ background: 'rgb(56, 65, 82)'}}>
          <h3 className="text-white">Mật khẩu rút tiền</h3>
          <div className="box-form mt-3">
            {user?.passsWithdraw && (
              <div className="form-group-custom mt-3">
                <CFormLabel
                  htmlFor="cardName"
                  className="text-white"
                  style={{ fontSize: 14 }}
                >
                  Mật khẩu cũ
                </CFormLabel>
                <CFormInput
                  onChange={(e) => onChange(1, e)}
                  type="password"
                  id="cardName"
                  value={currentPassword}
                  style={{ fontSize: 14 }}
                />
              </div>
            )}

            <div className="form-group-custom mt-3">
              <CFormLabel
                htmlFor="cardNumber"
                className="text-white"
                style={{ fontSize: 14 }}
              >
                Mật khẩu mới
              </CFormLabel>
              <CFormInput
                onChange={(e) => onChange(2, e)}
                type="password"
                id="cardNumber"
                value={newPassword}
                style={{ fontSize: 14 }}
              />
            </div>
            <div className="form-group-custom mt-3">
              <CFormLabel
                htmlFor="bankName"
                className="text-white"
                style={{ fontSize: 14 }}
              >
                Nhập lại mật khẩu mới
              </CFormLabel>
              <CFormInput
                onChange={(e) => onChange(3, e)}
                type="password"
                id="bankName"
                value={confirmNewPassword}
                style={{ fontSize: 14 }}
              />
            </div>
            <CButton onClick={onSubmit} className="mt-4 w-100" style={{ fontSize: 14, background: "rgb(183, 244, 227)", border: "none", color: "#000", boxShadow: "none"}}>LƯU THÔNG TIN</CButton>
          </div>
        </div>
    )
}

export default MatKhauRutTien