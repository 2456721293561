import { CCarousel, CCarouselItem, CCol, CImage, CRow } from '@coreui/react-pro'
import Layout from './Layout'
import banner1 from "../../../assets/img/banner/bn1.jpg";
import banner2 from "../../../assets/img/banner/bn2.jpg";

import { useEffect, useState } from "react";
import axios from "../../../shared/config/axios-interceptor";
import Marquee from "react-fast-marquee";

import game1 from "../../../assets/img/game/game1.png";
import game2 from "../../../assets/img/game/game2.png";
import game3 from "../../../assets/img/game/game3.png";
import game4 from "../../../assets/img/game/game4.png";
import game5 from "../../../assets/img/game/game5.png";
import game6 from "../../../assets/img/game/game6.png";
import game7 from "../../../assets/img/game/game7.jpg";


import { NavLink } from "react-router-dom";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import HeaderHome from './components/HeaderHome';

const HomeWap = () => {
  const [config, setConfig] = useState<any>()
  const { navigate } = useRouter();

  const getConfig = async () => {
    try {
      const response = await axios.get(
        '/config/1',
      )
      setConfig(response?.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getConfig();
  }, []);

  const game = [
    {
      name: "Venice nhanh 3",
      image: game1,
      id: 1,
    },
    {
      name: "Xổ số 5 điểm",
      image: game2,
      id: 2,
    },
    {
      name: "Tencent xổ số 2 điểm",
      image: game3,
      id: 3,
    },

    {
      name: "Tencent vé số",
      image: game4,
      id: 4,
    },

    {
      name: "May mắn phi thuyền",
      image: game5,
      id: 5,
    },
    {
      name: "Tốc độ cao PK đón",
      image: game6,
      id: 6,
    },

    {
      name: "Venice vé số",
      image: game7,
      id: 7,
    },
  ];

  return (
    <div className="home-wap">
      <Layout>

        <HeaderHome />


        <div className="main">
          <div className="banner-slide">
            <CCarousel controls interval={2000}>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner1} alt="slide 1" />
              </CCarouselItem>
              <CCarouselItem>
                <CImage className="d-block w-100" src={banner2} alt="slide 2" />
              </CCarouselItem>
            </CCarousel>
          </div>


          <div className="d-flex gap-2 pb-1 align-items-center my-2 px-1">
              <div>
               <i className="fas fa-volume-up" style={{ color: 'red'}}></i>
              </div>
              <Marquee style={{ fontSize: 13, color: "#fff" }} className="slogan">
              
                  <div>{config?.content}</div>
              </Marquee>
            </div>
          </div>
          
          <div className="box-info my-3 p-2">
            <div className="px-3 pb-2 mb-2 d-flex justify-content-end  align-items-center">
              <div className="d-flex justify-content-between w-100">
                <NavLink
                  to={'/message'}
                  style={{ fontSize: 14, color: '#fff' }}
                  className="text-decoration-none d-flex align-items-center gap-1"
                >
                <i className="fa-solid fa-wallet" style={{ color: '#fff', fontSize: 17 }}></i>
                  Nạp tiền
                </NavLink>
                <NavLink
                  to={'/account?tab=5'}
                  style={{ fontSize: 14, color: '#fff' }}
                  className="text-decoration-none d-flex align-items-center gap-1"
                >
                <i className="fa-solid fa-money-bill-transfer" style={{ color: '#fff', fontSize: 17 }}></i>
                  Rút tiền
                </NavLink>
                <NavLink
                  to={'/account?tab=1'}
                  style={{ fontSize: 14, color: '#fff' }}
                  className="text-decoration-none d-flex align-items-center gap-1"
                >
                  <i className="fa-solid fa-user" style={{ color: '#fff', fontSize: 17 }}></i>
                  Tài khoản
                </NavLink>

                <NavLink
                  to={'/voicher'}
                  style={{ fontSize: 14, color: '#fff' }}
                  className="text-decoration-none d-flex align-items-center gap-1"
                >
                  <i className="fa-solid fa-ticket" style={{ color: '#fff', fontSize: 17 }}></i>
                  Khuyến mãi
                </NavLink>
              </div>
            </div>


          <div className="box-games d-flex p-0 mt-2">
            <div className="box-list-game w-100 px-3">
              <CRow>
                {game.map((item, index) => {
                return (<CCol onClick={() => navigate(`/game/${item.id}`)} key={index} xs="3" className="d-flex flex-column align-items-center justify-content-center">
                  <div className="mb-2 text-center">
                    <img src={item.image} style={{width: "80%", aspectRatio: 1, objectFit: "cover"}} />
                  </div>
                  <div className="text-center" style={{ color: "#fff", fontSize: 14 }}>{item.name}</div>
                </CCol>)
                })}
              </CRow>
            </div>
          </div>
          <div className="text-white mt-4">
            <div dangerouslySetInnerHTML={{ __html: config?.content1 }}></div>
          </div>
        </div>
      </Layout>
    </div>
  );
};
export default HomeWap
