import { RootState } from "@/reducers"
import { CButton, CFormInput } from "@coreui/react-pro"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from '../../../../shared/config/axios-interceptor'
import { AppDispatch } from "@/store"
import { getProfile } from "../../auth/auth.api"
import { ToastSuccess } from "@/components/shared/toast/Toast"

const ThongTinTaiKhoan =() => {
    const dispatch = useDispatch<AppDispatch>()
    const { user } = useSelector((state: RootState) => state.authentication)
    const [fullName, setFullName] = useState(user?.fullName)

    const submit = async () => {
        try { 
            const a =  await axios.put('/user/' + user?.id, {
                id: user?.id,
                fullName: fullName
            })
            if(a){
                ToastSuccess('Lưu thông tin thành công')
                dispatch(getProfile())
            }
        }catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="p-4" style={{ background: "rgb(56, 65, 82)"}}>
            <h3 className="text-white">Thông tin tài khoản</h3>
            <div style={{ fontSize: 14 }} className="mb-2 mt-3 text-white">Tên tài khoản</div>
            <CFormInput style={{ fontSize: 14 }} value={user?.username}  readOnly/>
            <div style={{ fontSize: 14 }} className="mb-2 mt-3 text-white">Biệt danh</div>
            <CFormInput style={{ fontSize: 14 }} value={fullName} onChange={(e) => setFullName(e.target.value)}/>
            <CButton onClick={submit} className="mt-3 w-100" style={{ fontSize: 14, background: "rgb(183, 244, 227)", border: "none", color: "#000", boxShadow: "none"}}>LƯU THÔNG TIN</CButton>
        </div>
    )
}

export default ThongTinTaiKhoan