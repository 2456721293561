import { AppDispatch } from '@/store'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../../../shared/config/axios-interceptor'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { IGameName } from '@/shared/model/gameName.model'
import Layout from './Layout'
import HeaderHome from './components/HeaderHome'
import { RootState } from '@/reducers'
import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast'
import { getProfile } from '../auth/auth.api'
import KyQuay from './KyQuay'
import { IGame } from '@/shared/model/game.model'
import { IBiDatCuoc } from '@/shared/model/biDatCuoc.model'
import BoxQuay from './components/BoxQuay'

const Game = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { params } = useRouter()
  const { user } = useSelector((state: RootState) => state.authentication)


  const [game, setGame] = useState<IGameName | null>(null)
  const [showModalDatCuoc, setShowModalDatCuoc] = useState(false)
  const [boxCuoc, setBoxCuoc] = useState<any>([])
  const [daChon, setDaChon] = useState<any>(0)
  const [soTien, setSoTien] = useState<any>('')
  const [ky, setKy] = useState<any>()
  const [load, setLoad ] = useState(false)
  const [ketQuaKyTruoc, setKetQuaKyTruoc] = useState<IGame | null>(null)
  const [chonCuoc, setChonCuoc] = useState<IBiDatCuoc[]>([])
  const [loading, setLoading] = useState(false)
  const getGame = async (id: number) => {
    try {
      const response = await axios.get('/game/' + id)
      setGame(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }



  const getBoxCuoc = async () => {
    try {
      const response = await axios.get('/bidatcuoc/listcuoc/' + params?.id)
      setBoxCuoc(response.data[0]?.biDatCuoc)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getKy = async () => {
    try {
      const response = await axios.get('/draws/get-current/' + params?.id)
      setKy(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getKetQuaKyTruoc = async () => {
    try {
      const response = await axios.get(
        '/draws/get-result-ket-qua-ky-truoc/' + params?.id,
      )
      setKetQuaKyTruoc(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }


  useEffect(() => {
    getBoxCuoc()
    getKy()
    getGame(Number(params?.id) ?? 1);
    getKetQuaKyTruoc();
  }, [params?.id]);

  useEffect(() => {
    getKy()
    dispatch(getProfile())
    getKetQuaKyTruoc();
  }, [load])


  const datCuocHandle = (object: any) => {
    if (chonCuoc.some(it => it.biDatCuocId === object.biDatCuocId)) {
      let a = chonCuoc.filter(
        (item: any) => item.biDatCuocId !== object.biDatCuocId,
      )
      setChonCuoc(a)
    } else {
      setChonCuoc([...chonCuoc, object])
    }
  }


  const submitHanler = async () => {
    // if(user?.status === ResponseStatusUser.CAMGIAODICH) {
    //   ToastError('Bạn bị cấm đạt cược. Vui lòng liên hệ CSKH để đặt cược')
    //   setShowModalCuoc(false)
    //   setChonCuoc([])
    //   return;
    // }
    try {
      if ((user?.money ?? 0) < Number(soTien)) {
        ToastError('Số dư không đủ')
        return
      }

      if (soTien < 10) {
        ToastError('Số tiền cược phải lớn hơn 10')
        return
      }

      const data = {
        money: soTien,
        kyId: ky?.id,
        listDatCuoc: chonCuoc,
      }
      
      setLoading(true)
      const response = await axios.post('/history/create-all', data)      
      if (response.data) {
        ToastSuccess('Đặt cược thành công');
        setLoad(!load)
        setSoTien('')
        setShowModalDatCuoc(false)
        setLoading(false)
        setChonCuoc([])
      }
    } catch (error) {
      setChonCuoc([])
      setLoading(false)
      console.error('Error:', error)
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (Number.isNaN(Number(value))) return
    setSoTien(Number(value));
  }

  const renderKQ = () => {
    if(game?.bi === 3){
      const tong = Number(ketQuaKyTruoc?.ball1) + Number(ketQuaKyTruoc?.ball2) + Number(ketQuaKyTruoc?.ball3);
      let ketqua = [];
      if(tong.toString().length > 1){
        ketqua.push('Đôi')
      }

      if(tong.toString().length == 1){
        ketqua.push('Đơn')
      }
      if(tong > 18){
        ketqua.push('Lớn')
      }else {
        ketqua.push('Nhỏ')
      }
      return <>
          <div style={{ borderRadius: "50%", background: "rgb(255 193 7)", width: 20, height: 20, fontSize: 13, display: "flex", justifyContent: "center", alignItems: "center", color: "#fff"}}>{ketQuaKyTruoc?.ball1}</div>
          <div style={{ color: "rgb(249, 114, 114)"}}>+</div>
          <div style={{ borderRadius: "50%", background: "rgb(255 193 7)", width: 20, height: 20, fontSize: 13, display: "flex", justifyContent: "center", alignItems: "center", color: "#fff"}}>{ketQuaKyTruoc?.ball2}</div>
          <div style={{ color: "rgb(249, 114, 114)"}}>+</div>
          <div style={{ borderRadius: "50%", background: "rgb(255 193 7)", width: 20, height: 20, fontSize: 13, display: "flex", justifyContent: "center", alignItems: "center", color: "#fff"}}>{ketQuaKyTruoc?.ball3}</div>
          <div style={{ color: "rgb(249, 114, 114)"}}>={tong} ({ketqua.join(',')})</div>
          </>
    }


    const tong = Number(ketQuaKyTruoc?.ball1) + Number(ketQuaKyTruoc?.ball2) + Number(ketQuaKyTruoc?.ball3) + Number(ketQuaKyTruoc?.ball4) + Number(ketQuaKyTruoc?.ball5);
    let ketqua = [];
    if(tong.toString().length > 1){
      ketqua.push('Đôi')
    }

    if(tong.toString().length == 1){
      ketqua.push('Đơn')
    }
    if(tong > 18){
      ketqua.push('Lớn')
    }else {
      ketqua.push('Nhỏ')
    }

    return <>
      <div style={{ borderRadius: "50%", background: "rgb(255 193 7)", width: 20, height: 20, fontSize: 13, display: "flex", justifyContent: "center", alignItems: "center", color: "#fff"}}>{ketQuaKyTruoc?.ball1}</div>
      <div style={{ color: "rgb(249, 114, 114)"}}>+</div>
      <div style={{ borderRadius: "50%", background: "rgb(255 193 7)", width: 20, height: 20, fontSize: 13, display: "flex", justifyContent: "center", alignItems: "center", color: "#fff"}}>{ketQuaKyTruoc?.ball2}</div>
      <div style={{ color: "rgb(249, 114, 114)"}}>+</div>
      <div style={{ borderRadius: "50%", background: "rgb(255 193 7)", width: 20, height: 20, fontSize: 13, display: "flex", justifyContent: "center", alignItems: "center", color: "#fff"}}>{ketQuaKyTruoc?.ball3}</div>
      <div style={{ color: "rgb(249, 114, 114)"}}>+</div>
      <div style={{ borderRadius: "50%", background: "rgb(255 193 7)", width: 20, height: 20, fontSize: 13, display: "flex", justifyContent: "center", alignItems: "center", color: "#fff"}}>{ketQuaKyTruoc?.ball4}</div>
      <div style={{ color: "rgb(249, 114, 114)"}}>+</div>
      <div style={{ borderRadius: "50%", background: "rgb(255 193 7)", width: 20, height: 20, fontSize: 13, display: "flex", justifyContent: "center", alignItems: "center", color: "#fff"}}>{ketQuaKyTruoc?.ball5}</div>
      <div style={{ color: "rgb(249, 114, 114)"}}>={tong} ({ketqua.join(',')})</div>
      </>

  }

  return (
    <div className="home-wap">
      <Layout>
        <HeaderHome />

        <div className="text-center my-3">
          <img width="129" src={game?.imageDesktop ?? ''} />
          <div className="text-white">{game?.name}</div>
        </div>

        <div className="text-center py-2" style={{ background: "rgb(24 59 89)"}}>
          <div style={{color: "rgb(249 114 114)"}}>Kết quả mới nhất</div>
          <div className="d-flex justify-content-center mt-2 gap-1 align-items-center" style={{color: "rgb(249 114 114)"}}>
            {renderKQ()}
          </div>
        </div>


        <KyQuay load={load} ky={ky} setLoad={setLoad} setShowModalDatCuoc={setShowModalDatCuoc} showModalDatCuoc={showModalDatCuoc} />
        <BoxQuay ky={ky} game={game} />

        { 
        showModalDatCuoc ? <div className="box-chon-cuoc">
        <div onClick={() => setShowModalDatCuoc(false)} className="text-end px-3 py-2">
        <i className="fa-solid fa-xmark" style={{ fontSize: 25 }}></i>
        </div>
        <div className="text-center" style={{ fontSize: 12}}>Bấm để chọn kết quả cược, bấm lần nữa để bỏ chọn </div>

        <div className='list-chon'>
          {
            boxCuoc?.map( (item: any, index: number) => {
              return <div
                key={index} 
                // onClick={() => setDaChon(item?.biDatCuocId)} 
                style={{ color: "#000", width: 'fit-content', cursor: "pointer"}}
                className={chonCuoc.some(it => it.biDatCuocId === item.biDatCuocId) ? 'border rounded-5 px-4 py-1 text-center active' : 'border rounded-5 px-4 py-1 text-center'} 
                onClick={() => datCuocHandle(item)}
              >
                <div style={{ color: "#000", fontSize: 14, fontWeight: "bold"}}>{item?.name}</div>
                <div style={{ color: "#000", fontSize: 14, opacity: 0.7}}>{item?.rate}</div>
              </div>
            }) 
          }
        </div>

        <div>
          <input type="text" className="input-datcuoc" placeholder='Nhập số tiền cược' value={soTien} onChange={onChange} style={{ outline: "none"}} />
          <div className="text-center">
          <button disabled={loading} className="button-datcuoc" onClick={() => submitHanler()}>Đặt cược</button>
          </div>
          </div>
        </div> : ""
        }
      </Layout>
    </div>
  )
  
  // if (!game) return null

  // // Game PK
  // if (game.group === 1) {
  //   return <Pk10 game={game} />
  // }

  // // Game XX
  // if (game.group === 2) {
  //   return <Xoso game={game} />
  // }


  // return <Pk10 game={game} />
}

export default Game
