import { IGame } from '@/shared/model/game.model'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

interface IProps {
  ky: IGame | null
  game?: any
}

const getRandomNumber = () => Math.floor(Math.random() * 10) + 1

const BoxKetQua = (props: IProps) => {
  const {navigate} = useRouter();
  const { ky, game } = props

  const [random, setRandom] = useState<any>({
    ball1: getRandomNumber(),
    ball2: getRandomNumber(),
    ball3: getRandomNumber(),
    ball4: getRandomNumber(),
    ball5: getRandomNumber(),
    ball6: getRandomNumber(),
    ball7: getRandomNumber(),
    ball8: getRandomNumber(),
    ball9: getRandomNumber(),
    ball10: getRandomNumber(),
  })

  useEffect(() => {
    const gameEndTime = dayjs(ky?.endTime).add(2, 'seconds')

    const updateRandomValues = () => {
      const now = dayjs()
      const difference = gameEndTime.diff(now)
      const seconds = Math.floor(difference / 1000)
      if (seconds > 0 && seconds < 30) {
        setRandom({
          ball1: getRandomNumber(),
          ball2: getRandomNumber(),
          ball3: getRandomNumber(),
          ball4: getRandomNumber(),
          ball5: getRandomNumber(),
          ball6: getRandomNumber(),
          ball7: getRandomNumber(),
          ball8: getRandomNumber(),
          ball9: getRandomNumber(),
          ball10: getRandomNumber(),
        })
      } else {
        // setRandom(ketQuaKyTruoc)
      }
    }
    const intervalId = setInterval(updateRandomValues, 300)
    return () => clearInterval(intervalId)
  }, [ky?.endTime])

  return (
    <div className="d-flex justify-content-center gap-2 mt-2">
    {Array.from({ length: game?.bi ?? 10 }, (_, i) => i + 1).map(
      (_, index) => {
        const propertyName: any = `ball${index + 1}`
        const ball = random?.[propertyName as keyof IGame] ?? 0

        // Kiểm tra và xử lý giá trị ball nếu cần
        let ballValue: React.ReactNode = null
        if (typeof ball === 'number' || typeof ball === 'string') {
          ballValue = ball
        } else {
          ballValue = '' // hoặc giá trị mặc định phù hợp
        }
        return (
          <div key={index} style={{ borderRadius: "50%", background: "rgb(255 193 7)", width: 30, height: 30, fontSize: 17, display: "flex", justifyContent: "center", alignItems: "center", color: "#fff"}}>{ballValue}</div>
        )
      },
    )}
  </div>
  )
}

export default BoxKetQua