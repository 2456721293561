import { useDispatch, useSelector } from "react-redux";
import logo from "../../../../assets/img/xxnew/logo1.png";
import { RootState } from "@/reducers";
import { formatUSD } from "@/shared/utils/ultils";
import { AppDispatch } from "@/store";
import { logout, resetAll } from "../../auth/auth.reducer";


interface IProp {
    showBack?: boolean 
}

const HeaderHome = ( { showBack } : IProp) => {
    const { user } = useSelector((state: RootState) => state.authentication);
    const dispatch = useDispatch<AppDispatch>()
    const onLogout = () => {
        dispatch(logout())
        dispatch(resetAll())
   }
    return <div>
        <div onClick={() => window.history.back()} className="home_top justify-content-center w-100">
        <div className="home_top-left">
        <div className="home_top-logo soc text-center">
            <img height={70} src={logo} alt="logo" />
        </div>
        </div>
    </div>

    <div className="info">
        <div className="fw-bold text-white">{user?.username}</div>
        <div className="fw-bold text-white">Số dư: {formatUSD(user?.money || 0)}</div>
    </div>

    </div>
}

export default HeaderHome