import HeaderWap from "./components/HeaderWap";
import Layout from "./Layout";
import axios from "../../../shared/config/axios-interceptor";
import { useEffect, useState } from "react";

const Rule = () => {

    const [config, setConfig] = useState<any>()
  
    const getConfig = async () => {
      try {
        const response = await axios.get(
          '/config/1',
        )
        setConfig(response?.data?.content3)
      } catch (error) {
        console.error('Error:', error)
      }
    }
  
    useEffect(() => {
      getConfig();
    }, []);

    return (
        <div className="home-wap">
            <Layout>
                <HeaderWap title={"Luật chơi"} />
                
                <div className="p-3 text-white">
                    <div dangerouslySetInnerHTML={{ __html: config }}></div>
                </div>
            </Layout>
      </div>
    )
}
export default Rule