import { IGame } from '@/shared/model/game.model'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import BoxKetQua from '../BoxKetQua'

interface IData {
    ky: IGame | null
    game?: any
}
const BoxQuay = (props: IData) => {
  const { ky, game } = props
  const [timeInterval, setTimeInterval] = useState<any>(null)

  useEffect(() => {
    const gameEndTime = dayjs(ky?.endTime).add(2, 'seconds')

    const interval = setInterval(() => {
      const now = dayjs()
      const difference = gameEndTime.diff(now)

      if (difference > 0) {
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60),
        )
        const seconds = Math.floor((difference % (1000 * 60)) / 1000)
        let m = minutes < 10 ? '0' + minutes : minutes
        let s = seconds < 10 ? '0' + seconds : seconds
        if(minutes == 0 && seconds <= 30){
            setTimeInterval({ minutes: m, seconds: s })
        }
      } else {
        // setLoad(!load)
        setTimeInterval(null)
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [ky?.endTime])

  return (
    <div>
        {
            timeInterval && <div className="text-center py-2">
            <div style={{color: "#fff"}}>Đang quay số trong vòng <span style={{ color: "rgb(36, 99, 235)" }}>{timeInterval?.minutes}:{timeInterval?.seconds}</span></div>
            <BoxKetQua ky={ky} game={game}/>
          </div>
        }
    </div>
  )
}

export default BoxQuay
