import { ReactNode } from 'react'
import { matchPath } from 'react-router-dom'
import { Permission } from '../../shared/enumeration/permission'
import BellSidebarIcon from '../shared/icons/BellSidebarIcon'
import EmailIcon from '../shared/icons/EmailIcon'
import HeadphonesIcon from '../shared/icons/HeadphonesIcon'
import InfoCircleSidebar from '../shared/icons/InfoCircleSidebar'
import LockIcon from '../shared/icons/LockIcon'
import MessageAlertSquareSidebar from '../shared/icons/MessageAlertSquareSidebar'
import UserCircleIcon from '../shared/icons/UserCircleIcon'
import AccountIcon from '../shared/icons/web/AccountIcon'
import { AiFillCreditCard, AiFillMessage, AiFillMinusSquare, AiFillPlusSquare, AiFillProduct, AiFillStar, AiOutlineHistory } from 'react-icons/ai'
import SettingIcon from "../shared/icons/header/SettingIcon";

export const checkMatchPath = (pathArray: string[], pathName: string) => {
  let isActive = false;
  pathArray.forEach((path) => {
    const isMatch = matchPath({ path, end: false }, pathName);
    if (Boolean(isMatch)) {
      isActive = true;
      return;
    }
  });
  return isActive;
};
export interface SidebarItem {
  name: string;
  to: string;
  icon?: ReactNode;
  isTitle?: boolean;
  subItems?: SidebarItem[];
  permissions?: Permission[];
}

export const defaultSiderbarItems: SidebarItem[] = [
  {
    name: "Danh sách thành viên",
    to: "/admin/users",
    icon: <AccountIcon fill="#fff" className="custom-nav-icon" />,
  },
  {
    name: "Danh sách đại lý",
    to: "/admin/agents",
    icon: <AccountIcon fill="#fff" className="custom-nav-icon" />,
  },
  {
    name: "Quản lý xổ số",
    to: "/admin/games/1",
    icon: <AiFillProduct size={24} className="custom-nav-icon" />,
  },
  {
    name: "Quản lý tỷ lệ",
    to: "/admin/bidatcuoc/1",
    icon: <AiFillStar size={24} className="custom-nav-icon" />,
  },
  {
    name: "Lịch sử đặt cược",
    to: "/admin/history",
    icon: <AiOutlineHistory size={24} className="custom-nav-icon" />,
  },
  {
    name: "Quản lý rút điểm",
    to: "/admin/transaction",
    icon: <AiFillMinusSquare size={24} className="custom-nav-icon" />,
  },
  {
    name: "Quản lý nạp điểm",
    to: "/admin/recharge",
    icon: <AiFillPlusSquare size={24} className="custom-nav-icon" />,
  },
  {
    name: "Tin nhắn",
    to: "/admin/message",
    icon: <AiFillMessage size={24} className="custom-nav-icon" />,
  },

  {
    name: "Quản lý ngân hàng",
    to: "/admin/bank",
    icon: (
      <AiFillCreditCard size={24} color="#fff" className="custom-nav-icon" />
    ),
  },
  // {
  //   name: "Quản lý trò chơi",
  //   to: "/admin/game",
  //   icon: <InfoCircleSidebar className="custom-nav-icon" />,
  // },
  // {
  //   name: "Quản lý loại trò chơi",
  //   to: "/admin/game-type",
  //   icon: <InfoCircleSidebar className="custom-nav-icon" />,
  // },
  {
    name: "Cấu hình hệ thống",
    to: "/admin/config",
    icon: <SettingIcon fill="#fff" className="custom-nav-icon" />,
  },
];

export const userSettingSidebarItems: SidebarItem[] = [
  {
    name: "Thông tin hệ thống",
    to: "/admin/system-info",
    icon: <InfoCircleSidebar className="custom-nav-icon" />,
  },
  // {
  //   name: 'Quyền',
  //   to: '/admin/permissions',
  //   icon: <UserGroup className="custom-nav-icon" />,
  //   permissions: permissionGroupArray,
  // },
  // {
  //   name: 'Tài khoản',
  //   to: '/admin/users',
  //   icon: <UserCircleIcon className="custom-nav-icon" />,
  //   permissions: userManagementPermissionArray,
  // },
  {
    name: "Cài đặt kế hoạch và thông báo",
    to: "/admin/plan-notification-setting",
    icon: <BellSidebarIcon className="custom-nav-icon" />,
  },
  {
    name: "Hỗ trợ",
    to: "/admin/support",
    icon: <MessageAlertSquareSidebar className="custom-nav-icon" />,
  },
];

export const accountSettingSidebarItems: SidebarItem[] = [
  {
    name: "Thông tin tài khoản",
    to: "/admin/account/profile",
    icon: <UserCircleIcon className="custom-nav-icon" />,
  },
  {
    name: "Thay đổi mật khẩu",
    to: "/admin/account/change-password",
    icon: <LockIcon className="custom-nav-icon" />,
  },
  {
    name: "Thay đổi email",
    to: "/admin/account/change-email",
    icon: <EmailIcon className="custom-nav-icon" />,
  },
  {
    name: "Hỗ trợ",
    to: "/admin/account/support",
    icon: <HeadphonesIcon className="custom-nav-icon" />,
  },
];

export const handleSidebarItemsOnPathName = (path: string) => {
  if (
    checkMatchPath(
      userSettingSidebarItems.map((item) => item.to),
      path
    )
  ) {
    return userSettingSidebarItems;
  }

  if (
    checkMatchPath(
      accountSettingSidebarItems.map((item) => item.to),
      path
    )
  ) {
    return accountSettingSidebarItems;
  }
};

interface ITabItem {
  name: ReactNode;
  path: string;
}

export interface HeaderItem {
  name: ReactNode;
  to: string;
  tabItems?: ITabItem[];
  rightComponent?: ReactNode;
}

export const otherHeaderItem: HeaderItem[] = [
  {
    name: "Cài đặt hệ thống",
    to: "/admin/settings",
    tabItems: [
      { name: "Cấu hình hệ thống", path: "/admin/settings/system-info" },
      // { name: 'Quyền', path: '/admin/settings/permissions' },
      // { name: 'Tài khoản', path: '/admin/settings/users' },
      // { name: 'Cài đặt kế hoạch và thông báo', path: '/admin/settings/plan-notification-setting' },
      // { name: 'Hỗ trợ', path: '/admin/settings/support' },
    ],
  },
  {
    name: "Tài khoản của tôi",
    to: "/admin/account",
    tabItems: [
      { name: "Thông tin", path: "/admin/account/profile" },
      { name: "Bảo mật", path: "/admin/account/change-password" },
      { name: "Thông báo", path: "/admin/account/notifications" },
    ],
  },
  {
    name: "Quản lý sổ xố",
    to: "/admin/games",
    tabItems: [
      { name: "Venice nhanh 3", path: "/admin/games/1" },
      { name: "Xổ số 5 điểm", path: "/admin/games/2" },
      { name: "Tencent xổ số 2 điểm", path: "/admin/games/3" },
      { name: "Tencent vé số", path: "/admin/games/4" },
      { name: "May mắn phi thuyền", path: "/admin/games/5" },
      { name: "Tốc độ cao PK đón", path: "/admin/games/6" },
      { name: "Venice vé số", path: "/admin/games/7" },
    ],
  },
  {
    name: "Quản lý tỷ lệ",
    to: "/admin/bidatcuoc",
    tabItems: [
      { name: "Venice nhanh 3", path: "/admin/bidatcuoc/1" },
      { name: "Xổ số 5 điểm", path: "/admin/bidatcuoc/2" },
      { name: "Tencent xổ số 2 điểm", path: "/admin/bidatcuoc/3" },
      { name: "Tencent vé số", path: "/admin/bidatcuoc/4" },
      { name: "May mắn phi thuyền", path: "/admin/bidatcuoc/5" },
      { name: "Tốc độ cao PK đón", path: "/admin/bidatcuoc/6" },
      { name: "Venice vé số", path: "/admin/bidatcuoc/7" },
    ],
  },
];

/**
 * Return the header item that matches the given path.
 * The function will iterate through the default sidebar items and the other header items.
 * If a match is found, the function will return the header item.
 * If no match is found, the function will return null.
 * @param path The path to search for.
 * @returns The header item that matches the given path, or null if no match is found.
 */
export const handleGetHeaderItem = (path: string) => {
  const siderBarItem = [...defaultSiderbarItems]
  const convertSideBarItem = siderBarItem.map(({ name, to }) => ({ name, to }))
  const allHeaderItem = [...otherHeaderItem, ...convertSideBarItem]
  const headerItem = allHeaderItem.find((item) =>
    checkMatchPath([item.to], path),
  )
  if (headerItem) {
    return headerItem
  }
  return null
}
