import { useEffect, useState } from 'react'
import axios from '../../../../shared/config/axios-interceptor'
import { useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { formatUSD } from '@/shared/utils/ultils'

const LaiLoHomNay =() => {
    const { user } = useSelector((state: RootState) => state.authentication)

    const [data, setData] = useState<any>()

    const getKetQua = async () => {
        try {
          const response = await axios.get('/history/lai-lo')
          setData(response.data)
        } catch (error) {
          console.error('Error:', error)
        }
      }
    
      useEffect(() => {
        getKetQua()
      }, [])

    return (
        <div>
            <div className="d-flex">
                <div className="w-100 text-center border" style={{ background: "rgb(56, 65, 82)", padding: 10}}>
                    <div style={{ fontSize: 14 }} className="fw-bold text-white">Số dư tài khoản</div>
                    <div className="text-white">{formatUSD(user?.money ?? 0)}</div>
                </div>

                <div className="w-100 text-center border" style={{ background: "rgb(56, 65, 82)", padding: 10}}>
                    <div style={{ fontSize: 14 }} className="fw-bold text-white">Đặt cược hôm nay</div>
                    <div className="text-white">{formatUSD(data?.moneyTotal ?? 0)}</div>
                </div>

                <div className="w-100 text-center border" style={{ background: "rgb(56, 65, 82)", padding: 10}}>
                    <div style={{ fontSize: 14 }} className="fw-bold text-white">Lãi & lỗ hôm nay</div>
                    <div className="text-white">{formatUSD(data?.total ?? 0)}</div>
                </div>
            </div>


            {/* <div style={{ marginTop: 20, background: "#fff" }}>
                <div className="w-100" style={{ background: "#fff", padding: 10}}>
                    <div className="fw-bold py-3">Đặt cược hôm nay</div>
                </div>

                <div className="border-bottom"></div>

                <div className="w-100 d-flex justify-content-between px-3 py-2 align-items-center">
                    <div className="fw-bold">
                        <strong>20233213</strong>
                    </div>
                    <div>Thua</div>
                </div>


                <div className="w-100 d-flex justify-content-between px-3 py-2 align-items-center">
                    <div className="fw-bold">
                        <strong>20233213</strong>
                    </div>
                    <div>Thua</div>
                </div>
            </div> */}
        </div>
    )
}

export default LaiLoHomNay