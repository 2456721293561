import { RootState } from "@/reducers";
import { ITransaction } from "@/shared/model/transaction.model";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from '../../../../shared/config/axios-interceptor'
import { formatUSD } from "@/shared/utils/ultils";
import dayjs from "dayjs";

const LichSuRutTien =() => {

    const { user } = useSelector((state: RootState) => state.authentication);

    const [data, setData] = useState<ITransaction[] | []>([])

    const getData = async () => {
        try {
            const response = await axios.get(
                '/transaction?type=2&sortOrder=DESC&sortBy=id&limit=20&userId=' + user?.id,
            )
            setData(response.data.data)
        } catch (error) {
            setData([])
            console.error('Error:', error)
        }
    }


    useEffect(() => {
        getData();
    }, [])

    const renderStatus = (item: any) => {
        if(item.status === 1) {
            return <div style={{ color: '#00c400'}}>Đã duyệt</div>
        }

        if(item.status === 2) {
            return <div style={{ color: 'red'}}>Từ chối</div>
        }

        return <div style={{ color: 'rgb(59 130 246)'}}>Đang chờ duyệt</div>
    }


    const renderColor = (item: any) => {
        if(item.status === 1) {
            return '#00c400'
        }

        if(item.status === 2) {
            return 'red'
        }

        return 'rgb(59 130 246)'
    }

    
    return (
        <div className="p-4" style={{ background: 'rgb(56, 65, 82)'}}>
          <h3 className="text-white">Lịch sử rút tiền</h3>

          {

            data?.map( (item, index) => {
                return (
                    <div key={index} className="d-flex justify-content-between align-items-center mt-4">
                        <div>
                            <div style={{ fontSize: 14 }} className="text-white">#{item.id}</div>
                            <div style={{ fontSize: 14, color: renderColor(item), fontWeight: 'bold' }} >{formatUSD(item.money)}</div>
                            <div style={{ fontSize: 14 }} className="text-white">{dayjs(item.createdDate).format('HH:mm DD/MM/YYYY')}</div>
                        </div>
                        <div style={{ fontSize: 14 }} >
                            { renderStatus(item) }
                        </div>
                    </div>
                )
            })
          }
 
        </div>
    )
}

export default LichSuRutTien