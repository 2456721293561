import {
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow
} from '@coreui/react-pro';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { IBiDatCuoc, INewBiDatCuoc } from '@/shared/model/biDatCuoc.model';
import { RootState } from '@/reducers';
import { checkErrorInputFile } from '@/components/shared/TaskUploadContainer/FormikMultiFileUpload';
import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast';
import { fetching, resetAll, resetEntity } from './biDatCuocManagement.reducer';
import { ErrorMessage, mapErrorMessage } from '@/shared/enumeration/errorMessage';
import { createEntity, updateEntity } from './biDatCuocManagement.api';

interface IProcedureTaskModal {
  visible: boolean;
  onClose: () => void;
  gameSidebarId: number;
  gameId?: number;
  procedureTask?: IBiDatCuoc;
}

const BiDatCuocModal = (props: IProcedureTaskModal) => {
  const { visible, onClose, procedureTask, gameSidebarId, gameId } = props;
  const dispatch = useDispatch();
  const { initialState } = useSelector((state: RootState) => state.biDatCuocReducer);
  const { updateEntitySuccess, errorMessage } = initialState;
  const initialValues: IBiDatCuoc = {
    biDatCuocId: 0,
    name: '',
    rate: 0,
    order: 0,
    // group: 0,
    createdDate: '',
    latestMessage: null,
    avatar: '',
    lastModifiedDate: '',
    lastModifiedBy: '',
    // game: null,
    // gameSidebar: null,
    amount: 0,
    gameId: gameId,
    gameSidebarId: gameSidebarId
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required('Vui lòng tiêu đề'),
    rate: Yup.number().required('Vui lòng tỷ lệ'),

    // avatar: Yup.string().trim().required('Vui lòng chọn ảnh đại diện bi đặt'),
    // title: Yup.string().trim().required('Vui lòng nhập nhập Tên bi đặt').max(255, 'Số ký tự tối đa là 255 ký tự'),
    // description: Yup.string().trim().max(255, 'Số ký tự tối đa là 255 ký tự'),
    // content: Yup.string().trim().required('Vui lòng nhập nội dung'),
  });

  const handleFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    const inputFiles = e.target.files;
    if (inputFiles && inputFiles[0]) {
      if (!checkErrorInputFile([inputFiles[0]])) return;
      // setProfilePicFile(inputFiles[0]);
      const reader = new FileReader();
      reader.onload = (e) => {
        setFieldValue('fileArr', inputFiles[0]);
        setFieldValue('avatar', e.target?.result);
      };
      reader.readAsDataURL(inputFiles[0]);
    }
  };

  useEffect(() => {
    if (updateEntitySuccess) {
      ToastSuccess(procedureTask ? 'Chỉnh sửa bi đặt thành công' : 'Tạo mới bi đặt thành công');
      dispatch(resetEntity());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  useEffect(() => {
    if (errorMessage) {
      ToastError(mapErrorMessage[errorMessage as ErrorMessage] || 'Có lỗi xảy ra, xin vui lòng thử lại!');
      dispatch(resetAll());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const handleRemappingImageValue = async (value: INewBiDatCuoc) => {
    // if (value.fileArr) {
    //   const avatarUrlPromises = await handleUploadImage(value.fileArr);
    //   value.avatar = avatarUrlPromises;
    //   value.fileArr = undefined;
    // }
    return value;
  };

  return (
    <>
      <CModal visible={visible} onClose={onClose} backdrop="static">
        <CModalHeader className="fw-bold">{procedureTask ? 'Chỉnh sửa bi đặt' : 'Thêm mới bi đặt'}</CModalHeader>

        <Formik
          enableReinitialize
          initialValues={procedureTask || initialValues}
          validationSchema={validationSchema}
          onSubmit={async (value, { resetForm }) => {
            // const values = await handleRemappingImageValue(value);
            dispatch(fetching());
            if (procedureTask) {
              dispatch(updateEntity(value as IBiDatCuoc));
            } else {
              dispatch(createEntity(value));
            }
            resetForm();
          }}
        >
          {({ values, errors, touched, handleChange, setFieldValue, handleSubmit, resetForm, handleBlur }) => (
            <CForm onSubmit={handleSubmit}>
              {' '}
              <CModalBody className=" ">
                <CRow className="mb-3">
                  <CCol lg={12} xl={12}>
                    <CRow>
                      <CCol xs={12} className="mb-3">
                        <CFormLabel className="m-0 fw-bold required-field" htmlFor="title">
                          Tên bi đặt
                        </CFormLabel>
                        <CFormInput
                          invalid={!!errors.name && touched.name}
                          id="name"
                          name="name"
                          value={values.name}
                          placeholder="Nhập tên bi đặt"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <CFormFeedback invalid className={!!errors.name && touched.name ? 'd-block' : 'd-none'}>
                          {errors.name}
                        </CFormFeedback>
                      </CCol>
                      <CCol xs={12} className="mb-3">
                        <CFormLabel className="m-0 fw-bold" htmlFor="description">
                          Tỷ lệ
                        </CFormLabel>
                        <CFormInput
                          invalid={!!errors.rate && touched.rate}
                          id="rate"
                          name="rate"
                          value={values.rate}
                          placeholder="Nhập tỷ lệ"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <CFormFeedback
                          invalid
                          className={!!errors.rate && touched.rate ? 'd-block' : 'd-none'}
                        >
                          {errors.rate}
                        </CFormFeedback>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CModalBody>
              <CModalFooter className="border-0 d-flex justify-content-end">
                <CRow className="mb-3">
                  <CCol className="border-0 d-flex justify-content-end">
                    <CButton className="mx-2 custom-form-btn white-btn" onClick={onClose}>
                      Đóng
                    </CButton>
                    <CButton className="text-white custom-form-btn ms-2 green-btn" type="submit">
                      Lưu
                    </CButton>
                  </CCol>
                </CRow>
              </CModalFooter>
            </CForm>
          )}
        </Formik>
      </CModal>
    </>
  );
};
export default BiDatCuocModal;
