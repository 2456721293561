import { useDispatch } from "react-redux";
import logo from "../../../../assets/img/xxnew/logo1.png";
import { AppDispatch } from "@/store";
import { logout, resetAll } from "../../auth/auth.reducer";

interface IProp {
    title?: string;
}
const HeaderWap = ({ title }: IProp) => {
    const dispatch = useDispatch<AppDispatch>()
    const onLogout = () => {
        dispatch(logout())
        dispatch(resetAll())
   }

    return  <div>
        <div className="home_top d-flex align-items-center justify-content-between px-3">
                <div  onClick={() => window.history.back()}>
                    <i className="fa-solid fa-angle-left text-white" style={{ fontSize: 35 }}></i>
                </div>
                <div className="home_top-logo soc text-center">
                    <img height={70} src={logo} alt="logo" />
                </div>
                <div onClick={onLogout}>
                    <i className="fa fa-power-off text-white " style={{ fontSize: 20 }}></i>
                </div>
        </div>

        {
            title ? <div style={{ background: "rgb(24 59 89)", fontSize: 16, height: 44}} className="fw-bold text-center text-white align-content-center">
            {title}
            </div> : ""
        }
         
    </div>
}

export default HeaderWap