import ArrowLeft from '@/components/shared/icons/web/ArrowLeft'
import ArrowRight from '@/components/shared/icons/web/ArrowRight'
import { RootState } from '@/reducers'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

export const AccountInfo = () => {
  const { navigate } = useRouter()
  const { user } = useSelector((state: RootState) => state.authentication)

  return (
    <div className="container-wap">
      <div className="box-history-order box-history-order-wap box-account-info-wap">
        <div className="box-header d-flex justify-content-center align-items-center">
          <ArrowLeft
            style={{ position: "absolute", left: 16 }}
            onClick={() => navigate(-1)}
          />

          <h5 className="text-white text-center mb-0 mt-0">Thông Tin Cơ Bản</h5>
        </div>

        <div className="content-account-info-wap">
          <div className="account-top d-flex align-items-center">
            <div className="w-25 text-center">VIP{user?.vip ?? 1}</div>
            <div className="w-75 ps-3 border-start">
              <div>Đăng nhập gần đây</div>
              <div>Thời gian: 2024-07-14 03:29:39</div>
            </div>
          </div>

          <div className="tab-content">
            <NavLink to="/change-password">
              <span>Thay đổi mật khẩu đặng nhập</span>
              <ArrowRight color="#c7c7c7" width={12} />
            </NavLink>

            <NavLink to={"/mat-khau-rut-tien"}>
              <span>Thay đổi mật rút điểm</span>
              <ArrowRight color="#c7c7c7" width={12} />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
