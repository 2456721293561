import Layout from './Layout'
import { useEffect, useState } from 'react'
import axios from '../../../shared/config/axios-interceptor'
import { IHistory } from '@/shared/model/history.model'
import { RootState } from '@/reducers'
import { useSelector } from 'react-redux'
import { formatUSD, formatVND } from '@/shared/utils/ultils'
import HeaderWap from './components/HeaderWap'
import dayjs from 'dayjs'

const HistoryOrder = () => {
  const { user } = useSelector((state: RootState) => state.authentication)

  const [historys, setHistorys] = useState<IHistory[] | []>([])

  const getHistory = async () => {
    try {
      const response = await axios.get(
        '/history?state=SHOW&sortOrder=DESC&sortBy=historyId&limit=30&userId=' +
          user?.id,
      )
      setHistorys(response.data.data)
    } catch (error) {
      setHistorys([])
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getHistory()
  }, [])

  const renderKQ = (item: any) => {
    if (item.isWin || item.isWin == 0) {
      if (item.isWin == 1) {
        return (
          <div className="text-success">
            Thắng: {formatVND(item.addMoney ?? 0)}
          </div>
        )
      }
      return <div className="text-danger">Thua</div>
    }
    return <div className="text-warning">Đang chờ</div>
  }

  return (
    <Layout>
      <div className="box-history-order-wap">
      <HeaderWap title={"Số dư: " + formatUSD(user?.money ?? 0)} />
        <div className="box-history p-4">
          <div className="box-list" style={{ backgroundColor: '#fff' }}>
            <h4 className="p-3 border-bottom">Kỷ lục các cược</h4>
            {historys?.map((i: IHistory, index: number) => {
              return <>
                <div key={index} className="p-3 border-bottom d-flex justify-content-between" style={{ fontSize: 13 }}>
                  <div>
                    <strong>{i.KyId}</strong> | {dayjs(i.createdDate).format('HH:mm:ss DD/MM/YYYY')}
                    <div>Đặt cược: {i?.biDatCuoc?.name} | {formatUSD(i?.money ?? 0)}</div>
                    <div>
                      {
                        i?.isWin == 1 ? <strong>{'+' + formatUSD(i?.addMoney ?? 0)}</strong> : ''
                      }
                    </div>
                  </div>
                  <div className="fw-bold">{renderKQ(i)}</div>
                </div>
              </>
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HistoryOrder
