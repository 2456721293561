import { CButton, CFormInput, CFormLabel } from "@coreui/react-pro";
import { useState } from "react";
import axios from '../../../../shared/config/axios-interceptor';
import { ToastError, ToastSuccess } from "@/components/shared/toast/Toast";

const DoiMatKhau = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const onChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if(index === 1){
            setCurrentPassword(value)
        }
        if(index === 2){
            setNewPassword(value)
        }
        if(index === 3){
            setConfirmNewPassword(value)
        }
    }

    const onSubmit = async () => {

        if(!currentPassword || !newPassword || !confirmNewPassword){
            ToastError('Vui lòng nhập đầy đủ thông tin')
            return;
        }

        if(newPassword !== confirmNewPassword){
            ToastError('Xác nhận mật khẩu không khớp')
            return
          }

        try {
            const response = await axios.put('/auth/change-password', {
                currentPassword,
                newPassword,
                confirmNewPassword
            });
            if(response){
                ToastSuccess('Đổi mật khẩu thành công')
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
            <div className="p-4" style={{ background: "rgb(56, 65, 82)" }}>
                <h3 className="text-white">Đổi mật khẩu</h3>
                <div className="box-form mt-3">
                    <div className="form-group-custom mt-3">
                        <CFormLabel style={{ fontSize: 14 }} htmlFor="cardNumber" className="text-white">Mật khẩu mới</CFormLabel>
                        <CFormInput style={{ fontSize: 14 }} onChange={(e) => onChange(2, e)} type="password" id="cardNumber" value={newPassword}/>
                    </div>
                    <div className="form-group-custom mt-3">
                        <CFormLabel style={{ fontSize: 14 }} htmlFor="bankName" className="text-white">Nhập lại mật khẩu mới</CFormLabel>
                        <CFormInput  style={{ fontSize: 14 }} onChange={(e) => onChange(3, e)} type="password" id="bankName"  value={confirmNewPassword}/>
                    </div>
                    <div className="form-group-custom mt-3">
                        <CFormLabel style={{ fontSize: 14 }} htmlFor="cardName" className="text-white">Mật khẩu xác nhận</CFormLabel>
                        <CFormInput style={{ fontSize: 14 }} onChange={(e) => onChange(1, e)} type="password" id="cardName" value={currentPassword}/>
                    </div>
                    <CButton onClick={onSubmit} className="mt-4 w-100" style={{ fontSize: 14, background: "rgb(183, 244, 227)", border: "none", color: "#000", boxShadow: "none"}}>ĐỔI MẬT KHẨU</CButton>
                </div>


            </div>
    )
}

export default DoiMatKhau;