import { NavLink } from 'react-router-dom'

const MenuBottom = () => {
  return (
    <div className="menu-bottom-wap d-flex justify-content-between align-items-center">
      <NavLink
        to={"/"}
        className="text-center w-100 d-flex flex-column  align-items-center"
      >
        <i className="fa-solid fa-house"></i>
      </NavLink>
      <NavLink
        to={"/history-order"}
        className="text-center w-100 d-flex flex-column  align-items-center"
      >
        <i className="fa-solid fa-film"></i>
      </NavLink>
      <NavLink
        to={"/rule"}
        className="text-center w-100 d-flex flex-column align-items-center justify-content-end luatchoi"
        style={{ background: "#6b7280", color: "#fff", borderRadius: 25 }}
      >
        <div className="home-mneu-footer  py-2" >
          <span>Luật chơi</span>
        </div>
      </NavLink>
      
      <NavLink
        to={"/message"}
        className="text-center w-100 d-flex flex-column align-items-center"
      >
        <i className="fa-solid fa-headphones"></i>
      </NavLink>

      <NavLink
        to={"/account"}
        className="text-center w-100 d-flex flex-column align-items-center"
      >
        <i className="fa-solid fa-user"></i>
      </NavLink>
    </div>
  );
};

export default MenuBottom
